<!--

      This component displays a search bar to search for products.
      Furthermore it displays search results, i.e. products.
      This component is used in the shopping view.

 -->

<template>
  <div v-if="this.$store.state.neworder" :key="updateAllProducts">

    <div class="text-section secondary-text">Was brauchst du sonst noch?<br><br></div>
    
    <div @click="$store.dispatch('goTo', 'productsearch')">
      <div class="goto-search">
        <Icon name="searchOutline" class="icon-goto-search" />
        <span class="secondary-text-gray">Produkt</span>
      </div>
    </div>
    
    <ProductSection v-for="i in 3" :skeletonMode="true" :key="i" :newCardSkeleton="showSkeletonToAddOwnCategory(i)" v-show="loading" />
    <ProductSection v-for="section in $store.state.allProductsSections" :section="section" :key="section.title" />

  </div>
</template>

<script>
import ProductSection from '@/components/shopping/ProductSection.vue'

export default {
  name: 'productsearch',
  components: {
    ProductSection,
  },
  data () {
    return {
      categories: [
        {id: 4, name: "bread", name_german: "Brot & Gebäck", position: 8, type: 'search'},
        {id: 16, name: "frozen", name_german: "Fertig- & Tiefkühlprodukte", position: 9, type: 'search'},
        {id: 3, name: "fishmeat", name_german: "Fleisch & Fisch", position: 11, type: 'search'},
        {id: 10, name: "beverages", name_german: "Getränke", position: 3, type: 'search'},
        {id: 8, name: "milkcheeseeggs", name_german: "Milch, Käse & Eier", position: 7, type: 'search'},
        {id: 9, name: "nutsseeds", name_german: "Nüsse & Samen", position: 6, type: 'search'},
        {id: 1, name: "fruitsvegetables", name_german: "Obst & Gemüse", position: 1, type: 'search'},
        {id: 11, name: "oilsauces", name_german: "Öle & Saucen", position: 10, type: 'search'},
        {id: 5, name: "ricepotatopasta", name_german: "Reis, Nudeln & Kartoffeln", position: 12, type: 'search'},
        {id: 13, name: "snacks", name_german: "Snacks & Süßigkeiten", position: 2, type: 'search'},
        {id: 15, name: "other", name_german: "Haushalt & Co", position: 4, type: 'search'},
        {id: 12, name: "spices", name_german: "Zutaten & Gewürze", position: 5, type: 'search'}
      ],
      loading: null
    }
  },
  computed: {
    updateAllProducts () {
      let countProductsInSections = 0
      if (this.$store.state.allProductsSections) {
        countProductsInSections += this.$store.state.allProductsSections.map(ps => ps.page? ps.page.results.length : 0).reduce((pv, cv) => pv + cv, 0)
      }
      return this.$store.state.neworder.id + countProductsInSections
    }
  },
  methods: {
    showSkeletonToAddOwnCategory (index) {
      const itIsSecondPosition = index === 2
      const allSections = this.$store.state.allProductsSections
      const hasOwnCategories = allSections ? allSections.some(cat => cat.isOwnProductCategory) : false
      return itIsSecondPosition && !hasOwnCategories
    },
    async getShoppingCategories () {
      this.loading = true
      let preSections = []

      // 1. Get the category of last-ordered products
      await this.$store.dispatch('apiSend', { url: this.$store.state.api.shop.productRecommendations }).then(async results => {
        if (results.hasOwnProperty('results')) { 
          preSections.push(({ title: 'Zuletzt gekauft', page: results, category: 0, position: -2 }))
          preSections.push(({ title: 'Eigene Kategorie hinzufügen', page: null, category: 0, position: -1 }))
        }
      })

      // 2. Add custom user-made categories to the categories data object
      await this.$store.dispatch('getUserProductCategories')
      this.categories = this.categories.concat(this.$store.state.userProductCategories)

      // 3. Download all categories in parallel asynchronously and wait for all of them to be ready
      await Promise.all(this.categories.map(async cat => {
        const data = await this.getCategoryProducts(cat)
        const name = cat.name_german ? cat.name_german : cat.name
        if (data && data.results && data.results.length) {
          preSections.push({ 
            title: name, 
            page: data, 
            category: cat.id, 
            position: typeof cat.position === 'number' ? cat.position : - 3,
            isOwnProductCategory: cat.type !== 'search',
            productCategoryId: cat.id
          })
        } else if (cat.type !== 'search') {
          this.$store.dispatch('deleteUserProductCategory', cat.id)
        }
      }))

      // 4. Save and sort the categories
      const ownSections = preSections.filter(s => s.isOwnProductCategory).sort((a, b) => b.category - a.category)
      const otherSections = preSections.filter(s => !s.isOwnProductCategory).sort((a, b) => a.position - b.position)
      const sortedSections = ownSections.concat(otherSections)
      await this.$store.dispatch('save', { key: 'allProductsSections', value: sortedSections })
      this.loading = false
    },
    async getCategoryProducts (cat) {
      if (cat.type && cat.type === 'search') {
        // This category is not custom.
        return await this.$store.dispatch('apiSend', { url: this.$store.state.api.shop.productSearch + '?category=' + cat.id } ).then(results => {
          if (results.hasOwnProperty('results')) {
            return results
          }
        })
      } else {
        // This category is custom
        return await this.$store.dispatch('getUserProductCategoryProducts', {userProductCategoryId: cat.id} ).then(results => {
          return results
        })
      }
    }
  },
  mounted () {
    this.getShoppingCategories()
  }
}
</script>
