<!--

      This is a page for testing purposes.

 -->

<template>
  <Page 
    :hidelargeheader="true" 
    :hideheader="true" 
    :scroll="false" 
    :containsSlides="true" 
    :noPaddingTop="true" 
    :noPaddingBottom="true"
    :disableSwipeBack="true"
  >
    <Slides
      :redirect="this.redirect"
      @clicked="this.next"
      :analyticsEvent="'menu_wizard'"
      :hideNextButtonOnSlides="hideNextButtonOnSlides"
      :showBack="true"
      :navigateBack="this.goBack"
      :trackUniqueEvents="true"
      :progress="'bullets'"
      :cta="cta"
      :key="updateCheckoutSlides"
      :showLoadingButton="showLoadingButton"
      ref="shoppingSlides"
      :nameOfRoute="'checkout'"
    >
      <!-- Menü slide -->
      <Slide :title="name(this.$store.state.newplan)" :didyouorder="didyouorder" v-if="$store.getters.oldCustomerJourney">
        <template v-slot:header v-if="$store.state.newplan && $store.state.neworder">
          <Icon
            :name="$store.state.neworder.saved ? 'bookmark' : 'bookmarkOutline'"
            :class="['round-button-icon menu-header-button', {'icon-bookmarked': $store.state.neworder.saved}]"
            @click="this.toggleBookmark()"
          />
          <!-- <Icon
            name="shareOutline"
            class="round-button-icon menu-header-button"
            @click="$store.dispatch('sharePlan', $store.state.newplan)"
          /> -->
        </template>
        <div :key="this.updateMenu" class="menu-content" v-if="!this.$store.state.generatingPlan">
          <div class="info-tiles">
            <div class="info-tile-4" v-if="this.$store.getters.days" @click="$router.go(-1)">
              <div class="btn-link">{{ $store.getters.days.length }}</div>
              <div class="tertiary-text">Tage</div>
            </div>
            <div class="info-tile-4" v-if="this.$store.state.profile" @click="$store.dispatch('goTo', 'household')">
              <div class="btn-link">
                {{ $store.state.profile.adults + $store.state.profile.children * 0.5 }}
              </div>
              <div class="tertiary-text">Personen</div>
            </div>
            <ion-skeleton-text animated="true" v-if="this.$store.state.loadingNeworderPriceConsumed" class="info-tile-4"></ion-skeleton-text>
            <div class="info-tile-4" v-if="!this.$store.state.loadingNeworderPriceConsumed" @click="$store.dispatch('openModal', { name: 'pricehelpmenu' })">
              <div class="btn-link"><Price :price="this.$store.getters.theAvgPrice" /></div>
              <div class="tertiary-text">/ Portion</div>
            </div>
            <ion-skeleton-text animated="true" v-if="this.$store.state.loadingNeworderLeftovers" class="info-tile-4"></ion-skeleton-text>
            <div class="info-tile-4" v-if="!this.$store.state.loadingNeworderLeftovers" @click="$store.dispatch('openModal', { name: 'leftovers' })">
              <div class="btn-link">{{ $store.getters.leftoversConsumption }}%</div>
              <div class="tertiary-text">Verbrauch</div>
            </div>
          </div>
          <div v-for="(day, index) in days" :key="day.id" class="menu-section">
            <div class="section-title">Tag {{ index + 1 }}</div>
            <RecipeCard
              :rplan="day.planrecipes[0]"
              type="planning"
              :showAvailability="true"
            />
          </div>
          <div class="btn-secondary" data-cy="addrecipe" @click="this.$store.dispatch('goTo', 'discover')">Rezept hinzufügen</div>
        </div>
      </Slide>

      <!-- Durable goods slide -->
      <Slide :title="'weitere Rezeptzutaten'" :shoppingList="true" :didyouorder="didyouorder">
        <SmallAmountProducts />
      </Slide>

      <!-- Product search slide -->
      <Slide :title="'Alle Produkte'" :shoppingList="true" :didyouorder="didyouorder" v-if="$store.getters.oldCustomerJourney">
        <AllProducts />
      </Slide>

      <!-- Shopping list slide -->
      <Slide :title="'Warenkorb'" :didyouorder="didyouorder" v-if="$store.getters.oldCustomerJourney">
        <div v-if="this.$store.state.neworder">
          <div class="availability-notification" v-if="$store.getters.countUnavailableProductsInNeworder > 0">
            <div>
              <Icon name="alertCircleOutline" class="availability-icon" />
            </div>
            <div class="secondary-text text-white">
              {{ $store.getters.countUnavailableProductsInNeworder }}
              <span v-if="$store.getters.countUnavailableProductsInNeworder > 1">Produkte sind nicht mehr verfügbar. Bitte wähle Alternativen aus.</span>
              <span v-if="$store.getters.countUnavailableProductsInNeworder === 1">Produkt ist nicht mehr verfügbar. Bitte wähle eine Alternative aus.</span>
            </div>
          </div>
          <PartialList v-for="cat in shoppingList" :key="cat.name" :items="cat" />
          <div class="white-box-small total-bar">
            <div class="main-text text-left">
              foodable Service
            </div>
            <div class="main-text text-right">
              kostenlos
            </div>
          </div>
          <div 
            class="text-section secondary-text-gray text-left"
             v-if="this.$store.state.neworder.price < 50"
          >
            Du hast den Mindestbestellwert von 50€ noch nicht erreicht, 
            kannst aber über den REWE Abholservice bestellen.
          </div>
        </div>
      </Slide>

      <!-- Case 1: REWE checkout slide -->
      <Slide :title="'Jetzt zu REWE'" :didyouorder="didyouorder" v-if="$store.state.profile && !$store.state.profile.preferred_store_branch.use_internal_payment">
        <CheckoutREWE />
      </Slide>

      <!-- Case 1: Did you order? slide -->
      <Slide :title="'Hast du bei REWE bestellt?'" v-if="$store.state.profile && !$store.state.profile.preferred_store_branch.use_internal_payment">
        <div class="text-section secondary-text">
          Bitte sage uns ob du bei REWE bestellt hast. Wenn du auf ja klickst, 
          wird deine Bestellung ins "Kochen" Tab verschoben.
        </div>
        <div class="btn-primary" data-cy="ja" @click="this.yesIOrdered()">
          Ja, ich habe bestellt
        </div>
        <div class="btn-secondary" @click="this.didNotOrder()">
          Nein, wieder zurückgehen
        </div>
      </Slide>

      <!-- Case 2: Checkout slides internal payment: Select a delivery slot -->
      <Slide :title="'Lieferslot wählen'" :didyouorder="didyouorder" v-if="!useREWE && deliveryViaSlots">
        <DeliverySlot />
      </Slide>

      <!-- Case 2: Checkout slide internal payment: Pay now via Stripe -->
      <Slide :title="'Checkout'" :didyouorder="didyouorder" v-if="$store.state.profile && $store.state.profile.preferred_store_branch.use_internal_payment">
        <CheckoutInternalPayment />
      </Slide>

    </Slides>

    <!-- Green loading overlay -->
    <div class="generating-overlay" v-if="this.$store.state.generatingPlan">
      <div class="centered">
        <div class="text-section">
          <div class="headline">{{ loadingMessage }}</div>
        </div>
        <ion-spinner class="spinner-plan" name="crescent"></ion-spinner>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Slides from '@/components/slides/Slides.vue'
import Slide from '@/components/slides/Slide.vue'
import Price from '@/components/Price.vue'
import RecipeCard from '@/components/cards/RecipeCard.vue'
import PartialList from '@/components/shopping/PartialList.vue'
import SmallAmountProducts from '@/components/shopping/SmallAmountProducts.vue'
import AllProducts from '@/components/shopping/AllProducts.vue'
import CheckoutREWE from '@/components/shopping/CheckoutREWE.vue'
import CheckoutInternalPayment from '@/components/shopping/CheckoutInternalPayment.vue'
import DeliverySlot from '@/components/shopping/DeliverySlot.vue'
import * as Sentry from '@sentry/browser'

import {
  IonSkeletonText,
  IonSpinner,
} from '@ionic/vue'

export default {
  name: 'shopping',
  components: {
    Page,
    Slides,
    Slide,
    RecipeCard,
    PartialList,
    SmallAmountProducts,
    AllProducts,
    CheckoutREWE,
    CheckoutInternalPayment,
    DeliverySlot,
    IonSkeletonText,
    IonSpinner,
    Price
  },
  data() {
    return {
      generatingMessages: [
        "Suche Rezepte, die dir gefallen könnten!",
        "Reduziere deinen Lebensmittelabfall!",
        "Optimiere deinen Preis!",
        "Optimiere deinen Verbrauch von verderblichen Lebensmitteln!",
        "Suche Rezepte für deine Haushaltsgröße!",
        "Erstelle den Warenkorb für deine Rezepte!",
        "Suche Rezepte, die vollständig verfügbar sind!",
        "Suche Rezepte für weitere Empfehlungen!",
        "Der beste Ansatz zur Vermeidung von Lebensmittelverschwendung? Planen!",
        "Schließe Zutaten aus, die du nicht magst!"
      ],
      checkoutURL: null,
      shoppingList: null,
      updateCheckoutSlides: 0
    };
  },
  computed: {
    showLoadingButton () {
      if (this.$store.state.sendingToRewe === true || this.$store.state.runningPayment === true) {
        return true
      } else {
        return false
      }
    },
    useREWE () {
      return this.$store.state.profile && !this.$store.state.profile.preferred_store_branch.use_internal_payment
    },
    deliveryViaSlots () {
      return this.$store.state.profile && this.$store.state.profile.preferred_store_branch.delivery_via_slots
    },
    hideNextButtonOnSlides () {
      if (this.$store.getters.oldCustomerJourney) {
        // Old customer journey
        if (this.$store.state.profile && this.$store.state.profile.preferred_store_branch.use_internal_payment) {
          // Internal payment. Always show the next button.
          return []
        } else {
          // After coming back from REWE:
          return [5]
        }
      } else {
        // New customer journey
        if (this.$store.state.profile && this.$store.state.profile.preferred_store_branch.use_internal_payment) {
          // Internal payment. Always show the next button.
          return []
        } else {
          // After coming back from REWE:
          return [2]
        }
      }
    },
    didyouorder () {
      if (this.neworder && this.neworder.id === this.$store.state.neworderWithLastStepRewe && this.useREWE) {
        return true
      }
    },
    updateMenu () {
      // Add all recipe ids
      let sumOfAllRecipeIds = 0
      if (this.$store.getters.days && this.$store.getters.days.length > 0) {
        this.$store.getters.days.forEach(day => {
          if (day.planrecipes && day.planrecipes.length > 0) {
            sumOfAllRecipeIds += day.planrecipes[0].recipe.id
          }
        })
      }
      return sumOfAllRecipeIds
    },

    loadingMessage () {
      if (this.$store.state.generatingPlan) {
        return this.generatingMessages[Math.floor(Math.random() * this.generatingMessages.length)]
      }
    },

    days () {
      const days = this.$store.getters.days
      // Track recipe impressions for the menu recipes
      if (days) {
        Sentry.configureScope(function (scope) {
          scope.setExtra('days', JSON.stringify(days))
        })
        const recipeIds = days.map(day => day.planrecipes[0].recipe.id)
        const impressions = recipeIds.map(recipeId => (
          { recipe: recipeId, flag: this.$store.state.recipeImpressionFlags.SEEN_IN_MENU }
        ))
        this.$store.dispatch('recordRecipeImpression', impressions)
      }
      return days
    },

    neworder () {
      if (this.$store.state.neworder) {
        return this.$store.state.neworder
      }
    },

    cta () {
      if (this.$store.getters.oldCustomerJourney) {
        // Old customer journey with REWE
        if (this.useREWE) {
          return [
            'Zum Vorratscheck',
            'Zu den Produkten',
            'Zur Einkaufsliste',
            'Zum Checkout',
            'Jetzt zu REWE'
          ]
        } else {
          if (this.deliveryViaSlots) {
            return [
              'Zum Vorratscheck',
              'Zu den Produkten',
              'Zur Einkaufsliste',
              'Zu den Lieferslots',
              'Zur Kasse',
              'Kostenpflichtig bestellen'
            ]
          } else {
            return [
              'Zum Vorratscheck',
              'Zu den Produkten',
              'Zur Einkaufsliste',
              'Zur Kasse',
              'Kostenpflichtig bestellen'
            ]
          }
        }
      } else {
        if (this.useREWE) {
          return [
            'Zum Checkout',
            'Jetzt zu REWE'
          ]
        } else {
          if (this.deliveryViaSlots) {
            return [
              'Zum Checkout',
              'Zur Kasse',
              'Kostenpflichtig bestellen'
            ]
          } else {
            return [
              'Zum Checkout',
              'Kostenpflichtig bestellen'
            ]
          }
        }
      }
    },
  },
  watch: {
    neworder () {
      this.buildShoppingList()
    }
  },
  methods: {
    async buildShoppingList () {
      if (this.$store.state.neworder && this.$store.state.neworder.orderproducts) {
        let categories = []

        // Build the shopping list based on the order products and
        // their respective categories.
        this.$store.state.neworder.orderproducts.forEach(op => {
          if (op.quantity === 0 ) { 
            return
          }
          const cat = op.product_category
          const foundCategory = categories.find(c => c.name === cat)
          if (!foundCategory) {
            categories.push({
              name: cat,
              products: [op],
              hasUnavailable: false
            })
          } else {
            foundCategory.products.push(op)
          }
        })
        
        // Ensure the shopping list contains the original order products
        // This is required to show the amount that is needed for each
        // recipe in the product alternatives page (Product.vue).
        await Promise.all(categories.map(async cat => {
          cat.products = await this.$store.dispatch('mergeSimilarOrderProducts', cat.products)
 
          if (!this.$store.state.neworderUnavailableProducts) {
            await this.$store.dispatch('getNewOrderUnavailableProducts', this.$store.state.neworder)
          }
          // we need this check, because getNewOrderUnavailableProducts could return null
          cat.products.forEach(product => {
            if (this.$store.state.neworderUnavailableProducts) { 
              product['unavailable'] = this.$store.state.neworderUnavailableProducts.some(p => p.product === product.product_id)
              if (product['unavailable']) {
                cat['hasUnavailable'] = true
              }
            }
            product.visible = product.quantity > 0 || product.is_durable
          })
          cat['visible'] = cat.products.some(p => p.visible)
        }))
        
        // Save the final shopping list after sorting it by name of categories.
        this.shoppingList = categories.sort((a, b) => a.name.localeCompare(b.name))
      }
    },

    // saveNewMenuName (event) {
    //   let newName = event.detail.value
    //   if (newName === '') {
    //     newName = null
    //   }

    //   // 1. Save the change locally in the app
    //   //    a. In the 'newplan' state variable
    //   this.$store.state.newplan.name = newName

    //   //    b. In the 'openPlans' array
    //   let updatedOpenPlans = this.$store.state.openPlans.filter(p => p.id !== this.$store.state.newplan.id)
    //   updatedOpenPlans.push(this.$store.state.newplan)
    //   this.$store.state.openPlans = updatedOpenPlans

    //   // 2. Save the change offline in the api
    //   const details = {
    //     url: this.$store.state.api.plan.weekly,
    //     id: this.$store.state.newplan.id,
    //     method: 'PATCH',
    //     data: {
    //       name: newName
    //     }
    //   }
    //   this.$store.dispatch('apiSend', details)
    // },

    toggleBookmark () {
      // Toggle the bookmark icon's state
      let newBookmarkState = !this.$store.state.neworder.saved
      this.$store.state.neworder.saved = newBookmarkState
      this.$store.state.newplan.saved = newBookmarkState

      // Inform the backend
      const details = {
        url: this.$store.state.api.shop.order,
        id: this.$store.state.neworder.id,
        method: 'PATCH',
        data: {
          saved: newBookmarkState
        }
      }
      this.$store.dispatch('apiSend', details)

      // Update the savedPlans array
      if (this.$store.state.neworder.saved) {
        this.$store.state.savedPlans.unshift(this.$store.state.newplan)
      } else {
        this.$store.state.savedPlans = this.$store.state.savedPlans.filter(p => p.id !== this.$store.state.newplan.id)
      }
      this.$store.dispatch('save', { key: 'savedPlans', value: this.$store.state.savedPlans })
    },

    redirect () {
      if (this.useREWE) {
        this.$store.dispatch('goTo', 'cooking')
        this.updateCheckoutSlides ++
      } else {
        // Check if payment method and delivery slot is selected (if required)
        const weNeedAndHaveADeliverySlot = this.deliveryViaSlots && this.$store.state.neworderDeliverySlot
        const weDoNotNeedADeliverySlot = !this.deliveryViaSlots
        if ((weNeedAndHaveADeliverySlot || weDoNotNeedADeliverySlot)) {
          // Perform the checkout
          this.$store.state.runningPayment = true
          this.$store.dispatch('foodableOrdering').then(async () => {
            await this.$store.dispatch('goTo', 'cooking')
            this.updateCheckoutSlides ++
            this.$store.state.runningPayment = false
          })
        } else if (!this.$store.state.neworderDeliverySlot) {
          this.$store.dispatch('showNotification', { message: 'Bitte gehe einen Schritt zurück und wähle ein Lieferfenster aus, damit du bestellen kannst.', type: 'Error' })
        }
      }
    },

    yesIOrdered () {
      this.$store.dispatch('foodableOrdering', true)
      this.updateCheckoutSlides ++
    },

    goBack () {
      if (this.$store.state.routerHistory && this.$store.state.routerHistory.length) {
        this.$router.go(-1)
      } else {
        if (this.$store.getters.oldCustomerJourney) {
          this.$store.dispatch('goTo', 'shopping')
        } else {
          this.$store.dispatch('goTo', 'cart')
        }
      }
    },

    async next (slide) {
      if (this.$store.getters.oldCustomerJourney) {
        if (this.useREWE) {
          if (slide === 3) {
            this.sendProductsToREWE()
          } else if (slide === 4) {
            this.openREWE()
          }
        } 
      } else {
        // In the new customer journey, we need to open REWE earlier:
        if (this.useREWE) {
          if (slide === 1) {
            await this.sendProductsToREWE()
            await this.openREWE()
          }
        }
      }
    },

    async sendProductsToREWE () {
      if (this.$store.state.neworder && this.$store.state.neworder.price) {
        this.$store.dispatch('save', { key: 'sendingToRewe', value: true })
        this.checkoutURL = await this.$store.dispatch('getREWEcheckoutURL', this.$store.state.neworder.id)
        this.$store.dispatch('save', { key: 'sendingToRewe', value: false })
      } else {
        this.$store.dispatch('showNotification', {
          message: 'Huch. Deine Einkaufsliste ist leer, oder? Bitte füge Produkte hinzu, bevor wir dich zu REWE weiterleiten.',
          type: 'Error'
        })
      }
    },

    openREWE () {
      this.$store.dispatch('openUrl', this.checkoutURL)
      this.$store.dispatch('save', { key: 'neworderWithLastStepRewe', value: this.$store.state.neworder.id })
      this.$store.dispatch('logAnalyticsEvent', { name: 'transferred_to_partner' })
    },

    didNotOrder () {
      this.$store.dispatch('save', { key: 'neworderWithLastStepRewe', value: null })
      this.$router.go(-1)
      this.$store.dispatch('logAnalyticsEvent', { name: 'did_not_order' })
    },

    sortByPosition (a, b) {
      return a.position - b.position
    },

    name (plan) {
      if (plan) {
        if (plan.name) {
          return plan.name
        } else {
          const created = new Date(plan.created_at)
          return 'Box vom ' + created.getDate() + '.' + (created.getMonth() + 1) + '.' + created.getFullYear()
        }
      }
    },
  },
  mounted () {
    this.$store.dispatch('save', { key: 'tab', value: 'shopping' })
  },
  created () {
    this.$store.dispatch('save', { key: 'sendingToRewe', value: false })
  },
  updated () {
    this.buildShoppingList()
    // this.$store.dispatch('getPaymentIntent')
  }
}
</script>

<style lang="scss">
@import './../../theme/main.scss';

.main-bg {
  background: #{$col-white};
}

.generating-overlay {
  z-index: 10000;
  background: $col-pastell-green;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  color: $col-font;
}

.spinner-plan {
  --color: $col-font;
  width: 100px;
  height: 100px;
}

.total {
  display: block;
}

.menu-content {
  --padding-bottom: 100px;
}

.availability-notification {
  margin: 20px;
  background: $col-red;
  color: white;
  padding: 10px 10px;
  font-size: .9rem;
  border-radius: $border-radius;
  text-align: left;
  display: flex;
  align-items: center;
}

.availability-icon {
  height: 32px;
  width: 32px;
  --stroke: white;
  fill: white;
  margin-right: 10px;
}

.menu-section {
  margin-top: 30px;
}

.menu-header-button {
  margin-left: 5px;
}
</style>