<!--

      This component displays the REWE Checkout Page in the create plan
      wizard if the supermarket REWE is selected in the user profile.

 -->

<template>
  <div>
    <div class="text-section secondary-text">Wir haben deine Produkte an REWE übermittelt.</div>
    <img class="rewe-logo" src="../../assets/img/rewe.svg" alt="">
    <div class="section-title">So geht's nun weiter</div>
    <div class="white-box">
      <Step v-for="(step, index) in steps" :key="step" :number="index + 1">{{ step }}</Step>
    </div>
    <div class="text-section secondary-text-gray">Bei Fragen zum REWE-Sortiment, dem Shop oder der Lieferung, wende dich bitte an REWE.</div>
  </div>
</template>

<script>
import Step from '@/components/Step.vue'

export default {
  name: 'checkoutrewe',
  components: {
    Step
  },
  data () {
    return {
      steps: [
        'Klicke auf jetzt zu Rewe.',
        'Überprüfe und befülle dort deinen Warenkorb.',
        'Bestelle und bezahle digital im REWE Shop.'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.rewe-logo {
  max-width: 60% !important;
  border-radius: $border-radius;
}
</style>
