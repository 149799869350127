<!--

      This component allows to select a delivery slot during the checkout.
      Slots will be saved in frontend and backend.
      When a checkout happens the slot is removed from frontend.

 -->

<template>
  <div v-for="day in days" :key="day.day">
    <div class="section-title">{{ day.day }}</div>
    <div class="white-box">
      <div :class="['btn-toggle', { 'btn-toggle-selected': slotIsSelected(slot) }]" v-for="slot in day.slots.sort(sortByDate)" :key="slot.start_date_time" @click="selectSlot(slot)">
        <div class="main-text">{{ getTime(slot.start_date_time) + ' - ' + getTime(slot.end_date_time) }}</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'deliveryslot',
  data () {
    return {
      availableSlots: null
    }
  },
  computed: {
    days () {
      if (this.availableSlots) {
        const days = []
        this.availableSlots.forEach(slot => {
          const day = this.getDay(slot)
          const foundDay = days.find(d => d.day === day)
          if (foundDay) {
            foundDay.slots.push(slot)
          } else {
            days.push({
              day: day,
              slots: [slot]
            })            
          }
        })
        return days
      }
    }
  },
  methods: {
    getAvailableSlots () {
      if (this.$store.state.neworder) {
        const details = {
          url: this.$store.state.api.shop.order + this.$store.state.neworder.id + '/delivery-slot/'
        }
        this.$store.dispatch('apiSend', details).then(slots => {
          if (slots && slots.length) {
            this.availableSlots = slots
          }
        })
      }
    },
    getDay (slot) {
      const d = new Date(slot.start_date_time)
      const monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      const dayNames = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch',
        'Donnerstag', 'Freitag', 'Samstag']
      return dayNames[d.getDay()] + ', ' + d.getDate() + '. ' + monthNames[d.getMonth()]
    },
    getTime (time) {
      const d = new Date(time)
      return d.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
    },
    selectSlot (slot) {
      // Determine whether to save or remove a slot
      let data = null
      if (!this.slotIsSelected(slot)) {
        data = slot
      }

      // 1. Save the slot in the frontend
      this.$store.dispatch('save', { key: 'neworderDeliverySlot', value: data })
      
      // 2. Save the slot in the backend
      const details = {
        url: this.$store.state.api.shop.order + this.$store.state.neworder.id + '/delivery-slot/',
        method: 'POST',
        data: data
      }
      this.$store.dispatch('apiSend', details)
    },
    slotIsSelected (slot) {
      return slot === this.$store.state.neworderDeliverySlot
    },
    sortByDate (a,b) {
      return new Date(a.start_date_time) - new Date(b.start_date_time)
    }
  },
  mounted () {
    this.getAvailableSlots()
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  position: relative;
}
.icon-active-green {
  position: absolute;
  right: 0;
}
</style>