<!--

      This component displays the REWE Checkout Page in the create plan
      wizard if the supermarket REWE is selected in the user profile.

 -->

<template>
  <div class="white-box-small main-text">
    <div class="total-bar">
      <div class="main-text text-left">
        foodable Service
      </div>
      <div class="main-text text-right">
        kostenlos
      </div>
    </div>
    <div class="total-bar">
      <div>Dein Warenkorb</div>
      <Price v-if="this.$store.state.neworder && this.$store.state.neworder.price" :price="$store.state.neworder.price" />
    </div>
    <div class="total-bar">
      <div class="main-text text-left">
        Liefergebühr
      </div>
      <div class="main-text text-right">
        <Price :price="5" />
      </div>
    </div>
    <div class="total-bar">
      <div>Gesamt</div>
      <Price v-if="this.$store.state.neworder && this.$store.state.neworder.price" :price="$store.state.neworder.price + 5" />
    </div>
  </div>

  <Address />

  <div class="text-section secondary-text-gray">
    Klicke auf "Kostenpflichtig bestellen" um deine Lieferung zu erhalten. Du kannst dann an der Haustür bar oder mit Karte bezahlen. Es werden folgende Karten unterstützt: EC-Karte, Visa, Maestro, Mastercard, American Express,  Ticket Plus® Card (Edenred). 
  </div>
</template>

<script>
import Price from '@/components/Price.vue'
import Address from '@/components/settings/Address.vue'

export default {
  name: 'checkoutrewe',
  emits: ['clicked'],
  components: {
    Address,
    Price
  },
  computed: {
    fullAddressExists () {
      return this.$store.getters.fullAddressExists
    },
    inlineNotification () {
      if (!this.$store.getters.fullAddressExists) {
        return 'Bitte füge eine Lieferadresse hinzu.'
      }
    }
  },
  watch: {
    fullAddressExists () {
      this.$emit('clicked')
    }
  }
}
</script>
