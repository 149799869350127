<template>
  <div class="sap-box" @click="toggle()" v-if="orderProduct">
    <div class="sap-icon-area">
      <Icon name="checkmarkOutline" :class="sapIconClass()" />
    </div>
    <div v-if="orderProduct.img_url">
      <img :class="sapBoxClass()" v-show="!orderProduct || !orderProduct.isLoadedHQ" :src="orderProduct.img_url + '?output-format=jpeg&output-quality=70&resize=176px%3A176px'" alt="" />
      <img :class="sapBoxClass()" v-show="orderProduct && orderProduct.isLoadedHQ" :src="orderProduct.img_url + '?output-format=jpeg&output-quality=70&resize=400px%3A400px'" @load="orderProduct['isLoadedHQ'] = true" alt="" />
    </div>
    <span class="sap-name">{{ orderProduct.shopping_ingredient_name }}</span>
  </div>
</template>

<script>
export default {
  name: 'smallamountproduct',
  props: ['orderProductId'],
  computed: {
    orderProduct() {
      if (this.orderProductId && this.$store.state.neworder) {
        const foundOrderProduct = this.$store.state.neworder.orderproducts.find(orderProduct => orderProduct.id === this.orderProductId) 
        if (foundOrderProduct) return foundOrderProduct
      }
    },
    isAdded() {
      if (this.orderProduct) {
        return this.orderProduct.quantity > 0 ? true : false
      }
    }
  },
  methods: {
    sapBoxClass () {
      if (this.isAdded) {
        return "selected"
      } else {
        return "not-selected"
      }
    },
    sapIconClass () {
      if (this.isAdded) {
        return "icon-active-green pos-top-right-inset sap-icon-position"
      } else {
        return "icon-active-green pos-top-right-inset sap-icon-position icon-hidden"
      }
    },
    toggle () {
      if (!this.isAdded) {
        this.$store.dispatch('updateProduct', {
        product: this.orderProduct, 
        quantity: 1
        })
      }
      else {
        this.$store.dispatch('updateProduct', {
        product: this.orderProduct, 
        quantity: 0
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.sap-name {
  margin: 10px 0;
}

.sap-box {
  background: white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 10px 3px;
  position: relative;
  font-size: .77em;
}

.sap-icon-area {
  width: 100%;
  position: relative;
}

.sap-icon-position {
  top: -6px;
  right: 2px;
  position: absolute;
}

.icon-hidden {
  visibility: hidden;
}

.selected {
  opacity: 1;
}

.not-selected {
  opacity: .4;
  filter: grayscale(.8);
}
</style>
