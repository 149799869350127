<!--

      This component allows to edit the delivery and billing addresses.

 -->

<template>
  <div class="section-title">Lieferadresse</div>
  <div class="white-box">
    <div class="secondary-text">Gib die Adresse ein, wo du deine Lebensmittel-Lieferung erhalten möchtest.</div>
    <div class="address-field">
      <ion-input class="address-input" debounce="500" :value="$store.state.profile.full_name" placeholder="Vor- und Nachname" inputmode="text" type="text" onfocus="this.value = this.value;" enterkeyhint="next" @ionChange="enterName($event)"></ion-input>
    </div>
    <div class="address-field">
      <ion-input class="address-input" debounce="500" :value="$store.state.profile.delivery_address.address" placeholder="Straße, Hausnummer" inputmode="text" type="text" onfocus="this.value = this.value;" enterkeyhint="next" @ionChange="enterAddress($event)"></ion-input>
    </div>
    <div class="same-line">
      <div class="secondary-text" @click="$store.dispatch('goTo', 'supermarket')">{{ $store.state.profile.postal_code }}</div>
      <div class="address-field">
        <ion-input class="address-input" debounce="500" :value="$store.state.profile.delivery_address.city" placeholder="Stadt" inputmode="text" type="text" onfocus="this.value = this.value;" enterkeyhint="next" @ionChange="enterCity($event)"></ion-input>
      </div>
    </div>
    <div class="address-field">
      <ion-input class="address-input" debounce="500" :value="$store.state.profile.phone_number" placeholder="Telefonnummer" inputmode="tel" type="tel" onfocus="this.value = this.value;" enterkeyhint="next" @ionChange="enterTel($event)"></ion-input>
    </div>
    <div class="inline-notification">
      {{ phoneError }}
    </div>
  </div>
</template>

<script>
import {
  IonContent,
  IonInput
} from '@ionic/vue'

export default {
  name: 'household',
  components: {
    IonContent,
    IonInput
  },
  data () {
    return {
      phoneError: null
    }
  },
  methods: {
    enterName (event) {
      this.$store.dispatch('applySettings', { key: 'full_name', value: event.detail.value })
    },
    enterAddress (event) {
      let currentAddress
      if (this.$store.state.profile.delivery_address) {
        currentAddress = this.$store.state.profile.delivery_address
      }
      currentAddress.address = event.detail.value
      this.$store.dispatch('applySettings', { key: 'delivery_address', value: currentAddress })
    },
    enterCity (event) {
      let currentAddress
      if (this.$store.state.profile.delivery_address) {
        currentAddress = this.$store.state.profile.delivery_address
      }
      currentAddress.city = event.detail.value
      this.$store.dispatch('applySettings', { key: 'delivery_address', value: currentAddress })
    },
    enterTel (event) {
      this.phoneError = ''
      let phoneNumber = event && event.detail ? event.detail.value : ''
      if (phoneNumber[0] !== '+') {

        // If the user started the number with a 0, get rid of it
        if (phoneNumber[0] === '0') {
          phoneNumber = phoneNumber.substring(1)

          // If the user entere 0049 -> remove both 0
          if (phoneNumber[0] === '0') {
            phoneNumber = phoneNumber.substring(1)
          }

        }
        phoneNumber = '+49' + phoneNumber
      }

      this.$store.dispatch('applySettings', { key: 'phone_number', value: phoneNumber }).then(res => {
        if (res && res.phone_number && res.phone_number[0] === 'The phone number entered is not valid.') {
          this.phoneError = 'Deine Telefonnummer ist nicht gültig. Gültiges Beispiel: +49 123 4567890'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.same-line {
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  .address-field {
    margin: 0 0 0 6px;
    width: 100%;
  }
  .secondary-text {
    font-weight: 600;
  }
}

.address-field {
  border: solid 1px $col-light-gray-font;
  border-radius: $border-radius;
  margin: 8px 0 8px 0;
}

.address-input {
  display: inline-block;
  font-size: 1.16rem;
  background: white;
  border-radius: $border-radius;
  line-height: 1;
  text-align: left;
  border-left: solid white 20px !important;
}

</style>
