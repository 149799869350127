<!--

      This component displays Small Amount Products in the menu creation wizard.
      These were formerly known as durable goods.

 -->

<template>
    <div v-if="smallAmountOrderProducts && smallAmountOrderProducts.length > 0">
      <div class="text-section secondary-text">Das brauchst du in kleinen Mengen für deine Rezepte. Klicke an, was du nicht mehr Zuhause hast.</div>
      <div class="sap-grid">
        <SmallAmountOrderProduct 
          :orderProductId="orderProduct.id" 
          v-for="orderProduct in smallAmountOrderProducts" 
          :key="orderProduct.id" 
        />
      </div>
    </div>
    <div class="text-section" v-if="smallAmountOrderProducts && smallAmountOrderProducts.length === 0">
      <div class="text-section headline-text">
        Für diese Rezepte brauchst du keine weiteren haltbaren Zutaten
      </div>
      <div class="secondary-text">
        In diesem Schritt wirst du normalerweise gefragt, ob du noch
        haltbare Zutaten zu Hause hast, die du für die ausgewählten
        Rezepte brauchst. Für dieses Menü sind allerdings keine solchen
        Produkte nötig.
      </div>
    </div>
    <div v-if="!smallAmountOrderProducts">
      <div class="spinner-container">
        <ion-spinner name="crescent" class="centered sap-loading"></ion-spinner>
      </div>
    </div>
</template>

<script>
import AmountSelector from '@/components/AmountSelector.vue'
import SmallAmountOrderProduct from '@/components/shopping/SmallAmountOrderProduct.vue'

import {
  IonSpinner
} from '@ionic/vue'

export default {
  name: 'smallamountproducts',
  props: ['hideInfo'],
  components: {
    IonSpinner,
    AmountSelector,
    SmallAmountOrderProduct,
  },
  data () {
    return {
      smallAmountOrderProducts: [],
    }
  },
  computed: {
    orderProductsDidChange() {
      if (!this.$store.state.neworder) return null;
      if (!this.$store.state.neworder.orderproducts) return null;
      // for the new customer Journey we do not want this property to change
      // and trigger the watcher. In the new customer journey we can rely on
      // mounted.
      if (!this.$store.getters.oldCustomerJourney) return null;
      return this.$store.state.neworder.orderproducts
    }
  },
  watch: {
    orderProductsDidChange() {
      this.initializeSmallAmountOrderProducts()
    }
  },
  methods: {
    initializeSmallAmountOrderProducts () {
      if (!this.$store.state.neworder || !this.$store.state.neworder.orderproducts) return [];
      this.smallAmountOrderProducts = (
        this.$store.state.neworder.orderproducts.filter(
          orderproduct => orderproduct.is_durable === true
        ).sort((a, b) => a.shopping_ingredient_name.localeCompare(b.shopping_ingredient_name))
      )
    }
  },
  mounted() {
    this.initializeSmallAmountOrderProducts()
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.sap-loading {
  height: 100px;
  width: 100px;
  color: $col-light-gray-font;
}

.sap-grid {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-gap: 3vw 3vw;
  transition: 1s;
  margin: 20px;
}

</style>
